import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable, of, catchError, map} from "rxjs";
import {set} from "date-fns";

import {CalendarsService} from "./calendars.service";
import {CALENDARS_CONFIGURATION} from "../../di/calendars-registration.model";
import {
  CalendarAvailability,
  CalendarContact, CalendarEvent, CalendarGroup, CalendarMeInfo, CalendarSchedule,
  CalendarsConfiguration,
  CalendarUser
} from "../../models";

@Injectable({
  providedIn: "root"
})
export class EWSCalendarService extends CalendarsService {


  profileUrl = '/assets/images/profile.jpg';
  statusMap = [
    {
      key: 'reservationPopup.busy',
      value: 'Busy'
    },
    {
      key: 'reservationPopup.free',
      value: 'Free'
    },
    {
      key: 'reservationPopup.tentative',
      value: 'Tentative'
    },
    {
      key: 'reservationPopup.workingElsewhere',
      value: 'WorkingElsewhere'
    },
    {
      key: 'reservationPopup.away',
      value: 'OOF'
    }
  ];


  constructor(
    @Inject(CALENDARS_CONFIGURATION) private configuration: CalendarsConfiguration,
    private httpClient: HttpClient
  ) {
    super("ews");
  }


  private static getOptions() {
    return {withCredentials: true};
  }


  private getURL(postfix: string) {
    return this.configuration.ewsApiUrl + '/api/exchange' + postfix;
  }


  getContactsFromGroup(id: string): Observable<CalendarContact[]> {
    // throw new Error("Method not implemented.");
    return of([]);
  }


  findContactGroup(input: string): Observable<CalendarGroup[]> {
    // throw new Error("Method not implemented.");
    return of([]);
  }

  getMeInfo(): Observable<CalendarMeInfo> {
    const url = this.configuration.ewsApiUrl + '/api/exchange' + '/me';
    return this.httpClient.get<any>(url, {withCredentials: true}).pipe(
      map(obj =>
        (({
            businessPhones,
            givenName,
            displayName,
            surname,
            id,
            jobTitle,
            mobilePhone,
            officeLocation
          }) => ({
          businessPhones,
          givenName,
          displayName,
          surname,
          id,
          jobTitle,
          mobilePhone,
          officeLocation
        }))(obj)
      )
    );
  }

  getIdentifierFromContact(contact: CalendarContact) {
    return contact.userPrincipalName ?? contact.mail;
  }

  getImageUrl(identifier: string): Observable<string> {
    const url = this.configuration.ewsApiUrl + `/${identifier}/photos/240x240/$value`;
    return this.httpClient
      .get(url, {withCredentials: true}).pipe(
        map((x: any) => 'data:image/jpeg;base64,' + x.image),
        catchError(x => of(this.profileUrl)));
  }

  findContact(input: string): Observable<CalendarContact[]> {
    if (input === '') return of([]);
    const url = this.getURL('/contacts?search=' + input);
    return this.httpClient.get<any[]>(url, {withCredentials: true}).pipe(
      map((response: any[]) => response.map(r => ({
          ...r,
          mail: r.email,
          displayName: r.displayName,
          emailAddresses: [{address: r.email}]
        }))
      ));
  }


  deleteEvent(id: string): Observable<boolean> {
    return this.httpClient.post(this.getURL('/me/events/delete'), {id: id}, EWSCalendarService.getOptions()).pipe(
      map((x: any) => x.status === 204)
    );
  }

  finishEvent(eventId: string): Observable<{ id: string; start: Date; end: Date }> {
    return this.httpClient.post(this.getURL('/finish'), {
      id: eventId,
      end: new Date()
    }, EWSCalendarService.getOptions()).pipe(
      map((response: any) => {
        return {
          id: response.id,
          start: response.start,
          end: response.end
        };
      })
    );
  }

  patchEvent(
    visibility: "public" | "private",
    status: { key: string; value: string }, eventId: string, subject: string, text: string, start: Date, end: Date, roomName: string, roomEmail: string, attendees: { address: string; name: string; type: "required" | "optional" }[], onlineMeeting: { onlineMeetingProvider: string } | null = null): Observable<any> {
    return this.httpClient.post(this.getURL('/change'), {
      status: status.value,
      id: eventId,
      subject: subject,
      content: text,
      start: set(start, {seconds: 0, milliseconds: 0}),
      end: set(end, {seconds: 0, milliseconds: 0}),
      location: roomName,
      attendees: attendees
    }, EWSCalendarService.getOptions()).pipe(
      map((response: any) => {
        return [{id: response.id, response: ''}];
      })
    );
  }

  createEvent(
    visibility: "public" | "private",
    status: { key: string; value: string }, subject: string, text: string, start: Date, end: Date, roomName: string, roomEmail: string, attendees: { address: string; name: string; type: "required" | "optional" }[], onlineMeeting: null | { onlineMeetingProvider: string } = null, webexMeeting: null | { body: any; url: string; code: string; password: string } = null): Observable<{ id: string; response: string; iCalId: string }> {
    if (webexMeeting !== null) {
      let body = webexMeeting.body
        .replaceAll("{meeting-url}", "<a href='" + webexMeeting.url + "' target='_blank'>" + webexMeeting.url + "</a>")
        .replaceAll("{meeting-code}", webexMeeting.code)
        .replaceAll("{meeting-password}", webexMeeting.password);

      text = text + "<br/>" + body;
    }
    return this.httpClient.post(this.getURL('/create'), {
      status: status.value,
      subject: subject,
      content: text,
      start: set(start, {seconds: 0, milliseconds: 0}),
      end: set(end, {seconds: 0, milliseconds: 0}),
      location: roomName,
      attendees: attendees
    }, EWSCalendarService.getOptions()).pipe(
      map((response: any) => {
        return {id: response.id, response: '', iCalId: response.iCalId};
      })
    );
  }

  getCollegueInfo(identifier: string): Observable<CalendarUser> {
    const url = (this.getURL('/contacts?search=' + identifier));
    return this.httpClient.get<any>(this.getURL('/contacts?search=' + identifier), EWSCalendarService.getOptions()).pipe(
      map((response: any[]) => response.map(r => ({
          base64Image: null,
          businessPhones: null,
          givenName: r.displayName,
          id: null,
          jobTitle: null,
          mobilePhone: null,
          officeLocation: null,
          surname: null
        }))[0]
      ));
  }


  getScheduleForEmails(emails: string[], fromDate: Date, toDate: Date): Observable<CalendarSchedule[]> {
    const uniqueMails = emails.filter(this.onlyUnique);
    if (uniqueMails.length === 0) {
      return of([]);
    }

    const chunks = this.getChunks(uniqueMails, 100);
    const url = this.configuration.ewsApiUrl + '/api/exchange/calendars';
    const payload = {
      start: fromDate,
      end: toDate,
      groups: chunks.map(chunk => ({
        emails: chunk
      }))
    };

    if (payload.groups.length == 0) {
      return of([]);
    }


    return this.httpClient.post<any>(url, payload, EWSCalendarService.getOptions()).pipe(
      map((x: { responses: any }) => {
        const allItems = x.responses.reduce((acc, item) => [...acc, ...item.body.value], []);
         return emails.map(email => {
            const foundSchedule = allItems.find(x=> x.scheduleId === email);
            const item : CalendarSchedule = {
              serviceError: foundSchedule.serviceError,
              email: email,
              availabilityView: foundSchedule.availabilityView,
              workingHours: foundSchedule.workingHours,
              // TODO
              scheduleItems:    foundSchedule.events.map(sItem => ({
             subject: sItem.subject,
             location: sItem.location,
             availability: sItem.status == "free" ? CalendarAvailability.free : sItem.status == "tentative" ? CalendarAvailability.tentative : CalendarAvailability.reserved,
             start: new Date(sItem.start),
             end: new Date(sItem.end),
           }))
            };
            return item;
         });
      })
    );
  }

  availableMeetingProviders(): Observable<string[]> {
    return of([]);
  }

  getCalendarEvents(from: Date, to: Date, identifier: string, settings: any): Observable<{ events: CalendarEvent[]; nextLink: string}> {
    let url: string;
    if (settings?.nextLink == null) {
      url = this.configuration.ewsApiUrl + '/api/exchange/me/events?$top=10';
      if (!!from) {
        url = url + '&$filter=start/dateTime ge \'' + from.toISOString() + '\'';
      }

      if (!!to) {
        url = url + ' or end/dateTime ge \'' + to.toISOString() + '\'';
      }
    } else {
      url = settings.nextLink;
    }

    return this.httpClient.get<any>(url, EWSCalendarService.getOptions()).pipe(
      map(x => {
        return {
          ...x,
          events: x.value.map(a => ({
            ...a,
            visibility: 'public',
            bodyPreview: a.bodyPreview,
            bodyHtml: a.body.content,
            status: this.statusMap.find(c => c.value === (a as any).status) ?? {
              key: 'reservationPopup.busy',
              value: 'Busy'
            }
          }))
        };
      }),
      map((x: any) => {
        return {
          ...x,
          events: x.events
        };
      }),
      map(x =>
        {
          return {
            ...x,
            events: x.events.map(event => {
              const data: CalendarEvent = {
                isOrganizer: event.isOrganizer,
                visibility: 'public',
                status: event.status,
                isAllDay: event.isAllDay,
                attendees: event.attendees,
                end: event.end,
                start: event.start,
                organizer: event.organizer,
                subject: event.subject,
                locations: [event.location],
                id: event.id,
                iCalUId: event.iCalUId,
                type: event.type,
                seriesMasterId: event.seriesMasterId,
                onlineMeetingProvider: event.onlineMeetingProvider,
                bodyPreview: event.bodyPreview,
                bodyHtml:event.body.content,
              };
              return data;
            })
          }
        }
      )
    );
  }
}
